<template>
  <v-container class="bg-fullpage mb-2">
    <dashboard-core-app-bar v-if="showLayout" />

    <dashboard-core-loadings />

    <dashboard-core-view v-if="showLayout" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import cnst from '@/const/const'
import firebaseMessaging from '../firebase'
import 'firebase/firebase-messaging'
import router from '@/router'
import moment from 'moment'
import pushnote from '../api/api'

export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('@/components/core/AppBar'),
    DashboardCoreLoadings: () => import('@/components/core/Loading'),
    DashboardCoreView: () => import('@/components/core/View'),
  },
  watch: {
    $route(to, from) {
      this.showLayout = false
      this.checkStamp()
      console.log(from)
    },
  },
  data: () => ({
    expandOnHover: false,
    token: '',
    showLayout: false,
  }),
  methods: {
    ...mapActions('login', ['userLogin']),
    ...mapActions('stamp', ['getStamp']),
    async checkStamp() {
      const input = {
        mail: localStorage.getItem(cnst.STORAGE_EMAIL),
      }
      await this.getStamp(input)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.last_time != undefined) {
              let lasttime = moment(moment(res.data.last_time).format('YYYY-MM-DD'))
              if (moment(moment().format('YYYY-MM-DD')).diff(lasttime, 'days') == 0) {
                if (this.$route.path !== '/home') router.push({ path: '/home' })
                this.showLayout = true
              } else {
                if (this.$route.path !== '/stamp') router.push({ path: '/stamp' })
                this.showLayout = true
              }
            } else {
              if (this.$route.path !== '/stamp') {
                router.push({ path: '/stamp' })
              }
              this.showLayout = true
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  async created() {
    if (firebaseMessaging.messaging.isSupported()) {
      await firebaseMessaging.messaging().onMessage((payload) => {
        var notification = new Notification(payload.data['pinpoint.notification.title'], {
          icon: '/img/icons/favicon-32x32.png',
          body: payload.data['pinpoint.notification.body'],
        })
        notification.onclick = function () {
          window.open(pushnote.URL)
        }
      })
    }
  },
  async beforeMount() {
    this.checkStamp()
  },
}
</script>

<style>
.v-main__wrap {
  background-color: #f3f5ff;
}
</style>
